import { Component } from 'react';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { MediaDetail } from '../../types/MediaDetail';
import { Aspect, Summarization } from '../../types/Summarization';
import SummarizationTabItem from './SummarizationTabItem';

import './SummarizationTab.scss';
import { DataContext } from '../../context/DataProviderContext';

type SummarizationTabProps = {
  isPanelOpen: boolean, // ToDo: Is this still required? (copied from TranscriptionTab.tsx)       
  mediaInfo: MediaDetail,
  context: any
}

type SummarizationTabState = {
  summarization?: Summarization;
  parsedSummarization: ParsedSummarizationItem[];
}

export type ParsedSummarizationItem = {
  type: string;
  name: string;
  chapters: Aspect[];
}

class SummarizationTabInternal extends Component<SummarizationTabProps, SummarizationTabState> {
  context!: React.ContextType<typeof DataContext>;
  static contextType = DataContext;

  constructor(props: SummarizationTabProps) {
    super(props);
    this.state = {
      summarization: undefined,
      parsedSummarization: [],
    };
  }

  async componentDidMount() {
    const summarization = await this.props.context.getSummarization(this.props.mediaInfo.mediaId);
    const parsedSummarization: ParsedSummarizationItem[] = [];
    const recap: ParsedSummarizationItem = {
      type: 'recap',
      name: 'Recap',
      chapters: []
    };
    const issuesAndResolutions: ParsedSummarizationItem = {
      type: 'issues/resolutions',
      name: 'Issues / Resolutions',
      chapters: []
    };
    const narrative: ParsedSummarizationItem = {
      type: 'narrative',
      name: 'Narrative',
      chapters: []
    };

    summarization.aspects.forEach((aspect: Aspect) => {
      switch (aspect.name) {
        case 'recap':
          recap.chapters.push(aspect);
          break;
        case 'issue':
        case 'resolution':
          issuesAndResolutions.chapters.push(aspect);
          break;
        case 'narrative':
        case 'chapterTitle':
          narrative.chapters.push(aspect);
          break;
        default:
          return;
      }
    });

    recap.chapters.length && parsedSummarization.push(recap);
    issuesAndResolutions.chapters.length && parsedSummarization.push(issuesAndResolutions);
    narrative.chapters.length && parsedSummarization.push(narrative);

    this.setState({
      summarization: summarization,
      parsedSummarization: parsedSummarization,
    });
  }

  render() {

    return (
      <div className={`video-side-bar active`}>
        <div className="summarization">
          {this.state.summarization === undefined && (
            <div>Loading...</div>
          )}

          {this.state.summarization !== undefined && (
            <div className="summarization-content">
              {this.state.parsedSummarization.map((aspect) => {
                return (
                  <SummarizationTabItem key={aspect.type} aspect={aspect} />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const SummarizationTab = connectTeamsTheme(SummarizationTabInternal as any);