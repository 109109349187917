import { Component, useContext } from 'react';
import {Text, Table, TableHeader, TableBody, TableRow, TableCell, Button } from '@fluentui/react-components';
import { Tenant } from '../../types/Tenants';
import { connectTeamsTheme } from '../../context/connectTeamsTheme';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { DataContext, DataContextProps } from '../../context/DataProviderContext';

interface SwitchTenantProps {
    dataContext: DataContextProps;
    onClose(): void;
}

interface SwitchTenantState {
    tenants: Tenant[];
}

class SwitchTenantInternal extends Component<SwitchTenantProps, SwitchTenantState> {
    context!: React.ContextType<typeof AuthenticationContext>;
    static contextType = AuthenticationContext;

    constructor(props: SwitchTenantProps) {
        super(props);
        this.state = {                        
            tenants: [],
        };
    }

    async componentDidMount() {        
        var tenants = await this.props.dataContext.getTenants();
        tenants = tenants.sort((a, b) => a.label.localeCompare(b.label));
        this.setState(
            { 
                tenants: tenants, 
            })
    }

    handleSwitch = (tenantId: string) => {
        console.log(`Switching to tenant with id: ${tenantId}`);
        this.context?.switchTenant(tenantId);
        this.props.onClose();
    };

    render() {
        return (
            <>
            <section  >

            
                
                <Text>Switching tenants allow you to search and play media's for a different tenant.</Text>
                <br/>
                <br/>
                 {/* <Text>Tenant User: {this.context?.userTenantId}</Text> 
                 <br/>
                 <Text>Active Tenant Id: {this.context?.activeTenantId}</Text>  */}
                </section>
                
                <section >
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {this.state.tenants.map(tenant => (
                            <TableRow key={tenant.id}>
                                <TableCell>{tenant.label}</TableCell>
                                <TableCell>{tenant.id}</TableCell>
                                <TableCell>
                                    <Button disabled={tenant.id === this.context?.activeTenantId} appearance="primary" onClick={() => this.handleSwitch(tenant.id)}>Switch</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </section>
            </>
        );
    }
}

//export default connectTeamsTheme(SwitchTenant);
const SwitchTenant: React.FunctionComponent<SwitchTenantProps> = (props: SwitchTenantProps) => {
    const dataContext = useContext(DataContext);
    if (!dataContext) {
        throw new Error("No dataContext found in React tree.");
    }

    return (
        <SwitchTenantInternal {...props} dataContext={dataContext} />
    )
}

export default connectTeamsTheme(SwitchTenant);