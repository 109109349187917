import React, { Component, useContext } from 'react';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { MediaDetail } from '../../types/MediaDetail';
import { FaceButtonListItemSelectedEventArgs, FacesButtonList } from '../common/faces/FacesButtonList';
import { Face } from '../common/faces/types';
import { TimelineContext, TimelineEvent } from '../../context/TimelineContext';
import { DetectedFaces, Face as DetectedFace } from '../../types/Faces';
import { DataContext, DataContextProps } from '../../context/DataProviderContext';

type PeopleTabProps = {
    dataContext: DataContextProps;
    isPanelOpen: boolean, // ToDo: Is this still required? (copied from TranscriptionTab.tsx)   
    timeOffSetNavigationPointer: number,
    mediaInfo: MediaDetail,
    context: any
}

type PeopleTabState = {
    imageUrl?: string;
    faces?: Face[];
    detectedFaces?: DetectedFaces;
}

class PeopleTabInternal extends Component<PeopleTabProps, PeopleTabState> {  
    context!: React.ContextType<typeof TimelineContext>;
    
    constructor(props: PeopleTabProps) {
        super(props);
        this.state  = {
            imageUrl: undefined,
            faces: undefined,
            detectedFaces: undefined
        };  
      }

    async componentDidMount() {
        const detectedFaces = await this.props.context.getDetectedFaces(this.props.mediaInfo.mediaId);
        //The index of a face in the images sprite is base on the id. The highest id has  the lowest index number.
        const ids = detectedFaces.faces.map((detectedFace: DetectedFace) => detectedFace.id).sort((a: number,b: number) => a-b);
                
        const faces:Face[] = detectedFaces.faces.map((detectedFace: DetectedFace) => 
        {
                return {
                    imageUrl: detectedFaces.facesSpriteImage,
                    imageIndex: ids.indexOf(detectedFace.id),
                    id: detectedFace.id.toString(),
                    //Unknown #5 Appears in 3% of video
                    title: `${detectedFace.name} appears in ${ Math.round(detectedFace.seenDurationRatio * 100 * 100) / 100}% of video`
                }
        });
        
        this.setState({imageUrl: detectedFaces.facesSpriteImage, faces: faces, detectedFaces: detectedFaces});          
    }

    handleItemSelected = (e: FaceButtonListItemSelectedEventArgs) => {                
        let events: TimelineEvent[] = [];
        
        if (e.id !== undefined && this.state.detectedFaces !== undefined){
            
            const face = this.state.detectedFaces.faces.find(df => df.id.toString() === e.id);
            if(face !== undefined){
                events = this.createTimelineEvents(face);
            }             
        }      
        this.context.addEvents(events);        
    };

    createTimelineEvents = (face: DetectedFace) : TimelineEvent[] => {
        let eventItems: TimelineEvent[] = [];

        for (let appearance of face.appearances) {
            let eventItem: TimelineEvent = {
                offsetSeconds: appearance.startSeconds,
                durationInSecond: appearance.endSeconds - appearance.startSeconds,
                getTooltipTitle: () => face.name,
                getTooltipContent: () => {
                    return <span>From {appearance.startTime} to {appearance.endTime}</span>;
                }
            }
            eventItems.push(eventItem);
        }
        return eventItems;
    }

    render() {
        const {context} = this.props;
        return (
            <div className={`video-side-bar active`}>
                <FacesButtonList context={context} faces={this.state.faces ?? []} imagesUrl={this.state.imageUrl ?? ""} itemSelected={this.handleItemSelected} />
            </div>
        );
    }
}

PeopleTabInternal.contextType = TimelineContext;


const PeopleTab: React.FunctionComponent<PeopleTabProps> = (props: PeopleTabProps) => {
    const dataContext = useContext(DataContext);
    if (!dataContext) {
        throw new Error("No dataContext found in React tree.");
    }

    return (
        <PeopleTabInternal {...props} dataContext={dataContext} />
    )
}

export default connectTeamsTheme(PeopleTab);