import React, { Component, useContext, createRef} from 'react';
import './Search.scss'

import { Redirect } from 'react-router-dom';
import { SearchContext } from '../../context/SearchContext';
import { AuthorizationContext } from '../../context/AuthorizationContext';
import { MagicPixel } from '../MagicPixel';
import TopBarSearch from './TopBarSearch';
import SideBarSearch from './SideBarSearch';
import { SearchResult } from './SearchResult';
import { urls } from '../../logic/urls';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { Button, mergeClasses } from "@fluentui/react-components";
import * as utils from '../../utils/misc';


const mobileQueryWidth = 794
const offsetCacheKey = 'searchOffset';

class SearchInternal extends Component {

  static contextType = SearchContext;
  
  constructor(props) {
    super(props);

    this.state = {
      selectedMediaId: ''
    }
    this.timer = null
    this.resultBlockRef = createRef()
  }

  async componentDidMount() {

    const searchContext = this.context;
    await searchContext.triggerInitialisation();
    this.handleScrollPosition();
  }

  useMediaQueryWidth = (mediaWidth) => window.innerWidth <= mediaWidth

  handleScrollPosition = () => {
    const scrollPosition = +localStorage.getItem(offsetCacheKey);
    const resultBlock = this.useMediaQueryWidth(mobileQueryWidth) ? window : this.resultBlockRef?.current
 
    if (scrollPosition) {
      resultBlock.scrollTo({
        top: +scrollPosition
      });
      localStorage.removeItem(offsetCacheKey)
    }
  };

  setScrollPosOnTop = () => {
    const resultBlock = this.useMediaQueryWidth(mobileQueryWidth) ? window : this.resultBlockRef?.current
    resultBlock.style.overflow = 'hidden'
    resultBlock.style.paddingRight = '3.1rem'
    this.timer = setTimeout(() => {
      resultBlock.scrollTo({
        top: 0,
      })
      resultBlock.style.overflow = ''
      resultBlock.style.paddingRight = ''
    }, 1000);
  }

  componentWillUnmount(){
    clearTimeout(this.timer)
  }

  handleOnClick = async (mediaId) => {
    const currentScroll = this.useMediaQueryWidth(mobileQueryWidth) ? window.pageYOffset : this.resultBlockRef.current.scrollTop
    localStorage.setItem(offsetCacheKey, currentScroll.toString())
    this.setState({
      selectedMediaId: mediaId,
    });
  }

  handlePageUp = () => {
    const searchContext = this.context;
    searchContext.pageUp();
    this.setScrollPosOnTop()
  }

  handlePageDown = () => {
    const searchContext = this.context;
    searchContext.pageDown();
    this.setScrollPosOnTop()
  }

  handleLoadPage = (pageNumber) => {
    const searchContext = this.context;
    searchContext.loadPage(pageNumber)
    this.setScrollPosOnTop()
  }

  render() {

    // Redirect the user to the Play page when a Media has been clicked.
    if (this.state.selectedMediaId !== '') {
      const mediaParameter = `mediaId=${this.state.selectedMediaId}`;
      let playUrlWithParameters = `${urls.play}?${mediaParameter}`;

      const searchQuery = this.context?.searchSettings?.query;
      const searchFields = this.context?.searchSettings?.searchFields?.join(',');

      if (searchQuery) {
        playUrlWithParameters += `&query=${encodeURIComponent(searchQuery)}&searchFields=${encodeURIComponent(searchFields)}`;
      }

      return <Redirect push to={playUrlWithParameters}/>
    }

    const searchContext = this.context;
  
    const logoutButtonRendered = utils.isDebugEnabled() ?
      <header className="display--flex"><Button appearance="primary" onClick={this.props.authorizationContext.logout} style={{ marginLeft: 'auto', marginRight: '20px'}}>Log out</Button></header> :
      <></>
    return (
      <section style={{ minHeight: '100vh' }} className={this.props.styles.theme.body}>
        {logoutButtonRendered}
        <MagicPixel debugChanged={() => this.forceUpdate()}  />
        <section className="wrapper--top display--flex">
          <div className="top-bar width-100">
            <div className={this.props.styles.theme.container}>
              <div className='search-panel'>
                <TopBarSearch
                    searchSettings={searchContext.searchSettings}
                    autoCompleteResult={searchContext.autoCompleteResult}
                    fields={searchContext.fieldConfiguration ===undefined ? [] : searchContext.fieldConfiguration.fields}/>
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper display--flex display--grid pos">
          <div className={mergeClasses(this.props.styles.theme.container, 'panel-side-bar')}>
            <section className="side-bar">
              <SideBarSearch />
            </section>
          </div>

          <div className="main-content">
            <SearchResult
              innerRef={this.resultBlockRef}
              searchSettings={searchContext.searchSettings}
              searchResult={searchContext.searchResult}
              sorterConfig={searchContext.sorterConfig}
              onClick={this.handleOnClick}
              pageUp={this.handlePageUp}
              pageDown={this.handlePageDown}
              loadPage={this.handleLoadPage}
            />
          </div>
        </section>
      </section>
    );
  }
}

// Wrap the Search component so that the Teams Theme Context is accessible via the 'props.context'
const SearchWithTeamsTheme = connectTeamsTheme(SearchInternal);

// Wrap the wrapped Search component so that the AuthorizationContext is accessible via the 'props.userContext'
export const Search = (props) => {
  const authorizationContext = useContext(AuthorizationContext);

  return (
      <SearchWithTeamsTheme 
        key = {authorizationContext.activeTenantId} // This is to force a re-render when the active tenant changes
        authorizationContext={authorizationContext} 
        {...props}/>
  )
}