import { Component } from 'react';
import {AzureLocationSelectorProps, createAzMap} from './Azure/AzureLocationSelector'
import {LocationSelectorControl} from "./MapControl";
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import './LocationStyle.scss'
import { Slider } from '@fluentui/react-components';
import { DataContext } from '../../context/DataProviderContext';

interface LocationSelectorProps {
    radius: number
    onSelectedLocationChanged? (longitude: number, latitude: number): void;
    onRadiusChanged? (radius: number): void
}
interface LocationSelectorState {
    mapCreated: boolean;
    mapReadReady: boolean;
    radius: number;
}

export default class LocationSelectorInternal extends Component<LocationSelectorProps, LocationSelectorState> {
    context!: React.ContextType<typeof DataContext>;
    static contextType = DataContext;
    map!: LocationSelectorControl;
    constructor(props: LocationSelectorProps) {
        super(props);        
        this.state = {
            mapCreated: false,
            mapReadReady: false,
            radius: props.radius,
        }
    }

    componentDidMount() {
        this.createMap("location-selector-map");
    }

    componentDidUpdate() {
        if (!this.state.mapCreated){            
            this.createMap("location-selector-map");
        }

        if (this.state.mapReadReady){            
            
        }
      }

    createMap = (elementId: string) => {
        if (!this.context){
            throw new Error("No data context available");
        }

        let props: AzureLocationSelectorProps = {
            elementId: elementId,
            getAzureMapsToken: this.context?.getAzureMapsToken,
            onMapRead: this.onMapReady,
            radius: this.state.radius,
            onSelectedLocationChanged: this.props.onSelectedLocationChanged
        }
        this.map = createAzMap(props);

        this.setState({
            mapCreated: true
        });

    };

    onMapReady = async () => {        
        this.setState({
            mapReadReady: true
        });
    }

    radiusChanged = (e: any, data: any)  => {
        let radius = parseInt(data.value)
        this.setState({radius: radius});
        this.map.setRadius(radius);
        if(this.props.onRadiusChanged){
            this.props.onRadiusChanged(radius);
        }
    }

    render() {
        return (
            <>  
                <div>Set the radius to search for (meters):</div>
                <div className='position-selector'>
                    <Slider
                      className='selector-width'
                      min={100}
                      max={100000}
                      value={this.state.radius} 
                      onChange={this.radiusChanged}
                    />
                    <span className='distance-position'>{this.state.radius} meters</span>
                </div>
                <div id="location-selector-map" style={{ height: '50vh'  }} />
            </>
        )
    }
}

export const LocationSelector = connectTeamsTheme(LocationSelectorInternal as any);