import React, { Component } from 'react';
import { Avatar, Menu, MenuTrigger, MenuList, MenuItem, MenuPopover, DialogTitle, Dialog, DialogContent, Button, DialogActions, DialogSurface, DialogBody } from '@fluentui/react-components';
import { AuthorizationContext } from '../context/AuthorizationContext';
import css from './UserLogin.module.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SwitchTenantPage from './switchTenant/SwitchTenantPage';

interface UserLoginProps extends RouteComponentProps { }

class UserLogin extends Component<UserLoginProps> {
    context!: React.ContextType<typeof AuthorizationContext>;
    static contextType = AuthorizationContext;
    state = {
        isDialogOpen: false,
    };

    handleClick = () => {        
        this.context!.logoutRedirect();
    };

    handleSwitchTenantClick = () => {
        this.setState({ isDialogOpen: true });
    };

    handleDialogClose = () => {
        this.setState({ isDialogOpen: false });
    };

    render() {
        var inputProps: any = {
            className: css.Avatar,
            name: this.context!.displayName,
            'aria-label': 'Logged-in user',
        };

        if (this.context!.profilePicture) {
            inputProps['image'] = { src: this.context!.profilePicture };
        }
        
        const menuItems = [
            {
                key: '1',
                content: 'Switch Tenant',
                onClick: this.handleSwitchTenantClick,
                visible: this.context!.isDistributor(),
            },
            {
                key: '2',
                content: 'Sign out',
                onClick: this.handleClick,
                visible: true,
            }
        ];

        return (
            <>
                <Menu>
                    <MenuTrigger>
                        <Avatar {...inputProps} />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            {menuItems.map((item) => (
                                item.visible && (
                                <MenuItem key={item.key} onClick={item.onClick}>
                                    {item.content}
                                </MenuItem>
                                )
                            ))}
                        </MenuList>
                    </MenuPopover>
                </Menu>
                <Dialog open={this.state.isDialogOpen}>
                    <DialogSurface>
                        <DialogBody className={css.switchTenantDialog}>
                            <DialogTitle>Switch Tenant</DialogTitle>
                            <DialogContent>
                                <SwitchTenantPage onClose={this.handleDialogClose}/>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose}>Close</Button>
                        </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            </>
        );
    }
}

export default withRouter(UserLogin);