import React, { createContext, useContext } from 'react';
import { AuthenticationContext, AuthenticationContextInterface, AuthenticationContextProps, AuthenticationContextFunctions, AuthenticationStates } from './AuthenticationContext';
import { extractErrorMessage, ServerErrorResponse } from '../utils/errors';
import log from 'loglevel';
import { AuthorizeResponse } from './DataProviderContext';
import { appConfig } from '../logic/appConfigProvider';

// Enum defines the possible states of the authorizationState property of the state of the AuthenticationProvider.
export enum AuthorizationStates {
    'Undetermined' = 'UNDETERMINED', // The authorization has not been checked. This check is triggered when the authentication state reaches the AUTHENTICATED state.
    'Authorizing' = 'AUTHORIZING',
    'AuthorizationDone' = 'AUTHORIZATION_DONE', // The currently logged-in user is authorized to use this application. This means that the tenant has been signed up. No user-based checks are currently performed.
    'AuthorizationError' = 'AUTHORIZATION_ERROR'
}

export interface AuthorizationContextProps {
    authorizationState: AuthorizationStates,
    lastAuthorizationErrorMessage: string
}

export interface AuthorizationContextFunctions {
    isAuthenticated(): boolean,
    isAuthenticationInProgress(): boolean,
    isAuthorizationInProgress(): boolean,
    isAuthorizationDone(): boolean,
    isTenantKnown(): boolean,
    isReplayEnabled(): boolean | undefined,
    isDistributor(): boolean,
    hasAccess(requiredPermissions: string[]): boolean
}

// Note: The authorization context interface also contains the authentication interface.
export interface AuthorizationContextInterface extends AuthorizationContextProps, AuthenticationContextProps, AuthorizationContextFunctions, AuthenticationContextFunctions {
}

export const AuthorizationContext = createContext<AuthorizationContextInterface | undefined>(undefined);

interface AuthorizationContextProviderInternalProps {
    authenticationContext: AuthenticationContextInterface,
    children?: React.ReactNode,
}

interface AuthorizationContextProviderInternalState extends AuthorizationContextProps {
}

export class AuthorizationContextProviderInternal extends React.Component<AuthorizationContextProviderInternalProps, AuthorizationContextProviderInternalState> {
    state = {
        authorizationState: AuthorizationStates.Undetermined,
        lastAuthorizationErrorMessage: ""
    }

    authorizeResult: AuthorizeResponse | undefined;

    async componentDidMount(): Promise<void> {
        if (this.props.authenticationContext.authenticationState === AuthenticationStates.Authenticated) {
            await this.performAuthorization();
        }
    }

    async componentDidUpdate(prevProps: Readonly<AuthorizationContextProviderInternalProps>, prevState: Readonly<AuthorizationContextProviderInternalState>, snapshot?: any): Promise<void> {
        if (this.props.authenticationContext.authenticationState === AuthenticationStates.Authenticated) {
            if (prevProps.authenticationContext.authenticationState !== AuthenticationStates.Authenticated) {
                await this.performAuthorization();
            }
        }
        else {
            if (this.state.authorizationState !== AuthorizationStates.Undetermined) {
                this.setState({
                    authorizationState: AuthorizationStates.Undetermined
                });
            }
        }
    }

    getAuthorize = async (): Promise<AuthorizeResponse> => {
        return await this.executeJsonMethodOnApi('GET', 'v2/authorize', null);
    };

    private async executeJsonMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null): Promise<any> {
        const response = await this.executeMethodOnApi(method, relativeApiPath, jsonBody);
        if (response.status === 204) {
            return Promise.resolve(null);
        }
        return await response.json();
    }
    
    private async executeMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null): Promise<Response> {
        try {
            log.debug(`[executeCallOnBackend] Invoking '${method}' on '${relativeApiPath}'...`)

            const apiTokenResponse = await this.props.authenticationContext.getApiToken();
            
            if (!apiTokenResponse) {
                throw new Error("No API token received from token provider callback");
            }

            const basePath = apiTokenResponse.isSso ? 'replay-api/app' : 'replay-api';
            const url = `https://${appConfig.replayApiHostname}/${basePath}/${relativeApiPath}`;

            const requestHeaders: HeadersInit = new Headers();
            requestHeaders.set('Authorization', `Bearer ${apiTokenResponse.token}`);

            const requestParameters: RequestInit = {
                method: method,
                headers: requestHeaders
            };

            // Add the body if one was provided.
            if (jsonBody) {
                requestHeaders.set('Content-Type', 'application/json');
                requestParameters.body = JSON.stringify(jsonBody);
            }

            const response = await fetch(url, requestParameters);

            if (!response.ok) {
                // Some non-success response received.
                throw new ServerErrorResponse(null, response.status, response.statusText, await response.text());
            }

            // A success response is received from the back-end.
            return response;
        }
        catch (error: any) {
            if (error instanceof ServerErrorResponse) {
                const responseBody = error.responseBody ? `\r\n${error.responseBody}` : "";
                log.error(`Server error response received while invoking '${method}' on '/${relativeApiPath}' endpoint. Received ${error.statusCode} '${error.statusText}'.${responseBody}`);
            } else {
                log.error(`Error occurred while invoking '${method}' on '/${relativeApiPath}': ${error.message}`);
            }

            throw (error);
        }
    }
    performAuthorization = async (): Promise<void> => {
        this.clearLastError();
        this.setState({
            authorizationState: AuthorizationStates.Authorizing
        });

        try {
            this.authorizeResult = await this.getAuthorize();
            this.setState({
                authorizationState: AuthorizationStates.AuthorizationDone
            });
        }
        catch (error) {
            log.error(`[AuthorizationContext, performAuthorization] Error while invoking the Authorize method: ${error}. New AuthorizationState is 'AuthorizationError'.`);

            this.setLastError(error);
            this.setState({
                authorizationState: AuthorizationStates.AuthorizationError
            });
        }
    }

    isAuthenticated = (): boolean => {
        return this.props.authenticationContext.authenticationState === AuthenticationStates.Authenticated;
    }

    isAuthenticationInProgress = (): boolean => {
        return this.props.authenticationContext.authenticationState === AuthenticationStates.Authenticating;
    }

    isAuthorizationInProgress = (): boolean => {
        const authenticationState = this.props.authenticationContext.authenticationState;

        // Check if the authentication step is still in progress...
        if (authenticationState === AuthenticationStates.Authenticating) {
            return true;
        }

        // The authentication is not in progress (so either not started, failed, or successful),
        // check if the authorization is in progress...
        // Note: The state will be 'Undetermined' for a very short time, right after the Authentication state has become Authenticated and before this component has updated its state to 'Authorizing'.
        if (authenticationState === AuthenticationStates.Authenticated &&
            (this.state.authorizationState === AuthorizationStates.Undetermined || this.state.authorizationState === AuthorizationStates.Authorizing)) {
            return true;
        }

        // In all other situations the authorization is not in progress.
        return false;
    }

    isAuthorizationDone = (): boolean => {
        return this.state.authorizationState === AuthorizationStates.AuthorizationDone;
    }

    isTenantKnown = (): boolean => {
        if (!this.isAuthorizationDone()) {
            return false;
        }

        return this.authorizeResult!.isTenantKnown;
    }

    isDistributor = (): boolean => {
        if (!this.isAuthorizationDone()) {
            return false;
        }
        
        return this.authorizeResult!.isDistributor;
    }

    isReplayEnabled = (): boolean | undefined => {
        if (!this.isAuthorizationDone()) {
            return false;
        }

        // The isReplayEnabled might not be available on all API's.
        if (this.authorizeResult!.isReplayEnabled === undefined) {
            return undefined;
        }

        return this.authorizeResult!.isReplayEnabled;
    }

    hasAccess = (allowedUserPermissions: string[]): boolean => {
        if (allowedUserPermissions.length === 0) {
            // No specific user permissions required, only a successful authenticated user is required. 
            return true;
        }

        for (const userPermission of this.authorizeResult!.userPermissions) {
            if (allowedUserPermissions.includes(userPermission)) {
                return true;
            }
        }

        return false;
    }

    setLastError = (error: any): void => {
        this.setState({
            lastAuthorizationErrorMessage: extractErrorMessage(error)
        });
    }

    clearLastError = (): void => {
        this.setState({
            lastAuthorizationErrorMessage: ""
        });
    }

    render() {
        return (
            <AuthorizationContext.Provider value={{
                authenticationState: this.props.authenticationContext.authenticationState,
                displayName: this.props.authenticationContext.displayName,
                preferredUserName: this.props.authenticationContext.preferredUserName,
                profilePicture: this.props.authenticationContext.profilePicture,
                lastAuthenticationErrorMessage: this.props.authenticationContext.lastAuthenticationErrorMessage,
                userTenantId: this.props.authenticationContext.userTenantId,
                getApiToken: this.props.authenticationContext.getApiToken,
                loginRedirect: this.props.authenticationContext.loginRedirect,
                logoutRedirect: this.props.authenticationContext.logoutRedirect,
                isSSO: this.props.authenticationContext.isSSO,
                isOnMobile: this.props.authenticationContext.isOnMobile,
                switchTenant: this.props.authenticationContext.switchTenant,
                activeTenantId: this.props.authenticationContext.activeTenantId,


                authorizationState: this.state.authorizationState,
                lastAuthorizationErrorMessage: this.state.lastAuthorizationErrorMessage,
                isAuthenticated: this.isAuthenticated,
                isAuthenticationInProgress: this.isAuthenticationInProgress,
                isAuthorizationInProgress: this.isAuthorizationInProgress,
                isAuthorizationDone: this.isAuthorizationDone,
                isTenantKnown: this.isTenantKnown,
                isReplayEnabled: this.isReplayEnabled,
                isDistributor: this.isDistributor,
                hasAccess: this.hasAccess,
            }}>
                {this.props.children}
            </AuthorizationContext.Provider>
        )
    }
}

export interface AuthorizationContextProviderProps {
    children?: React.ReactNode,
};

// Wrap the wrapped AuthorizationContextProviderInternal component so that the AuthenticationContext is accessible via the 'props.authenticationContext'
export const AuthorizationContextProvider: React.FunctionComponent<AuthorizationContextProviderProps> = (props: AuthorizationContextProviderProps) => {
    const authenticationContext = useContext(AuthenticationContext);
    if (!authenticationContext) {
        throw new Error("No Authentication Context found in React tree.");
    }

    return (
        <AuthorizationContextProviderInternal authenticationContext={authenticationContext} children={props.children} {...props} />
    )
}