import { Component } from "react";
import "./PlayHeader.scss";
import { Redirect } from "react-router-dom";
import { urls } from "../../logic/urls";
import MediaActionsMenu from "../common/menu/MediaActions";
import {
  ArrowLeft32Filled as ArrowLeftIcon,
  ArrowRight32Filled as ArrowRightIcon,
} from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { connectTeamsTheme } from "../../context/connectTeamsTheme";
import { MediaDetail } from "../../types/MediaDetail";


interface PlayHeaderInternalProps {
    mediaInfo:MediaDetail | null,
    styles: any,
    onDownload:()=>void,
}

interface PlayHeaderInternalState{
    backToSearch:boolean,
}

class PlayHeaderInternal extends Component<PlayHeaderInternalProps,PlayHeaderInternalState> {
  constructor(props:PlayHeaderInternalProps) {
    super(props);
    this.state = {
      backToSearch: false,
    };
  }

  render() {
    if (this.state.backToSearch) {
      return <Redirect push to={`${urls.search}`} />;
    }
    if (!this.props.mediaInfo) return;

    const { owner, direction, participants } = this.props.mediaInfo;
    const icon =
      direction === "incoming" ? <ArrowLeftIcon /> : <ArrowRightIcon />;
    const  participantsExceptOwner = participants.filter(item => item.participantId !== owner.participantId);
    const arrowRender = (
        <>
          {owner.displayName}
          {participantsExceptOwner.length > 0 && (
            <>
              <Button
                appearance="transparent"
                style={{ height: "11px", padding: "0" }}
                icon={icon}
              />
              {participantsExceptOwner[0].displayName}
            </>
          )}
        </>
      );

    return (
      <header className={`${this.props.styles.theme.container} header`}>
        <h2>{arrowRender}</h2>
        <div className="actions-wrapper">
          <MediaActionsMenu
            mediaId={this.props.mediaInfo.mediaId}
            onDownload={this.props.onDownload}
          />
          <Button
            appearance="primary"
            className={this.props.styles.theme.button}
            onClick={() => this.setState({ backToSearch: true })}
          >
            Back to search
          </Button>
        </div>
      </header>
    );
  }
}

export const PlayHeader = connectTeamsTheme(PlayHeaderInternal);
