import { Component, useContext } from 'react';
import MediaTraceMap from '../map/MediaTraceMap'
import { MediaDetail } from '../../types/MediaDetail';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { DataContext, DataContextProps } from '../../context/DataProviderContext';

type LocationTabProps = {
    dataContext: DataContextProps;
    isPanelOpen: boolean, // ToDo: Is this still required? (copied from TranscriptionTab.tsx)   
    timeOffSetNavigationPointer: number,
    mediaInfo: MediaDetail
}

type LocationTabState = {
}

class LocationTabInternal extends Component<LocationTabProps, LocationTabState> {    

    state = {
    };  

    componentDidMount() {
    }

    componentDidUpdate(prevProps: LocationTabProps) {

    }



    render() {
        return (
            <div className={`video-side-bar active`}>
                <MediaTraceMap play={false} isPanelOpen={this.props.isPanelOpen} timeOffSetNavigationPointer={this.props.timeOffSetNavigationPointer}
                    startTime={this.props.mediaInfo.timeStart} mediaId={this.props.mediaInfo.mediaId}/>
            </div>
        );
    }
}
  
const LocationTab: React.FunctionComponent<LocationTabProps> = (props: LocationTabProps) => {
      const dataContext = useContext(DataContext);
      if (!dataContext) {
          throw new Error("No dataContext found in React tree.");
      }
  
      return (
          <LocationTabInternal {...props} dataContext={dataContext} />
      )
  }

  export default connectTeamsTheme(LocationTab);