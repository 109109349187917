import React, { Fragment, Component, useContext } from 'react';
import './TopBarSearch.scss';

import { mergeClasses, Button } from "@fluentui/react-components";
import { AuthorizationContext, AuthorizationContextInterface } from '../../context/AuthorizationContext';
import { SearchContext, SearchContextProviderState } from '../../context/SearchContext';
import SearchBox from './SearchBox';
import  UserLogin  from '../UserLogin';
import SearchInDropDown, { SearchInDropDownChangedEventArgs } from './SearchInDropDown';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";

interface AutoCompleteResult {
    values: string[];
}

interface SearchSettings {
    query: string;  
}

interface TopBarSearchInternalProps {
    autoCompleteResult: AutoCompleteResult;
    searchSettings: SearchSettings;
    authorizationContext: AuthorizationContextInterface,
    searchContext: SearchContextProviderState,
    /** context is MS Teams Theme context. You don't need to set this property, because is't automatically set by HOC connectTeamsComponent. */
    context?: any;
    styles: any;
}

interface TopBarSearchState {
    searchQuery: string
    searchFields: string[];
}

const cacheTopBarFilter = 'cacheTopBarFilter'

class TopBarSearchInternal extends Component<TopBarSearchInternalProps, TopBarSearchState> {
    timeoutId!: number;
    constructor(props: TopBarSearchInternalProps) {
        super(props);
        this.state = {
            searchQuery: this.props.searchSettings.query,
            searchFields: [],
        }
    }

    componentDidUpdate(prevProps: TopBarSearchInternalProps, prevState: TopBarSearchState) {
        if (prevState !== this.state) {
            localStorage.setItem(cacheTopBarFilter, JSON.stringify(this.state))
        }
    }

    getSearchContext = (): React.ContextType<typeof SearchContext> => {
        return this.props.searchContext;
    }

    startAutoComplete = (suggestQuery: string, searchFields: string[]) => {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = window.setTimeout(() => this.executeAutoComplete(suggestQuery, searchFields), 200);
    }

    executeAutoComplete = (suggestQuery: string, searchFields: string[]) => {
        const searchContext = this.getSearchContext();
        searchContext.executeAutoCompleteQuery(suggestQuery, searchFields);
    }

    onSuggest = (suggestQuery: string) => {
        if (this.canShowSuggestions(this.state.searchFields)) {
            this.startAutoComplete(suggestQuery, this.state.searchFields);
        }
    }

    canShowSuggestions = (searchFields: string[]): boolean => {
        if (searchFields.length === 0) {
            return true;
        }
        let field = this.getSearchContext().fieldConfiguration?.fields.find(fc => searchFields.find(fieldName => fc.name === fieldName && fc.systemSettings.supportSuggestions));

        return field ? true : false;
    }

    onSuggestClear = () => {
        this.props.searchContext.updateAutoCompleteClear();
    }

    executeSearch = (query: string, fields: string[]) => {
        localStorage.removeItem("facetSelections")
        this.props.searchContext.updateSearchQuery(query, fields);
    }

    onSearch = () => {
        this.executeSearch(this.state.searchQuery, this.state.searchFields);
    }

    onSearchByQuery = (query: string) => {
        this.executeSearch(query, this.state.searchFields);
    }

    onSearchByFields = (fields: string[]) => {
        this.executeSearch(this.state.searchQuery, fields);
    }

    onQueryChanged = (query: string) => {
        this.setState({ searchQuery: query });
    }

    onSearchInDropDownChanged = (e: SearchInDropDownChangedEventArgs) => {
        let fields: string[] = []

        if (e.selectedItem !== undefined) {
            fields = [e.selectedItem];
        }

        this.setState({ searchFields: fields });

        this.onSearchByFields(fields);
    }

    onSuggestionsFetchRequested = (query: any) => {
        //console.log(`[onSuggestionsFetchRequested] Reason ${query.reason}, value: ${query.value}`);
    }

    shouldDisplayUserLogin = () : boolean => {
        return !this.props.authorizationContext.isSSO();
    }

    render() {
        let suggestions: string[] = [];

        if (this.props.autoCompleteResult.values && this.props.autoCompleteResult.values.length > 0) {
            suggestions = this.props.autoCompleteResult.values.map((autoComplete, index) => {
                return autoComplete;
            });
        }

        const userLoginRendered = this.shouldDisplayUserLogin() ? 
            <UserLogin /> : 
            <></>;

        return (
            <Fragment>
                <div className="wrapper-input width-100">
                    <div className="search-field">
                        <SearchBox searchQuery={this.state.searchQuery} suggestionsFetchRequested={this.onSuggestionsFetchRequested} suggestions={suggestions} suggest={this.onSuggest} suggestClear={this.onSuggestClear} search={this.onSearchByQuery} queryChanged={this.onQueryChanged} />
                    </div>
                    <div className="searchin-dropdown">
                        <SearchInDropDown
                            fields={this.props.searchContext.fieldConfiguration === undefined ? [] : this.props.searchContext.fieldConfiguration.fields}
                            onChange={this.onSearchInDropDownChanged}
                        />
                    </div>
                    <Button
                        appearance="primary"
                        className={mergeClasses(this.props.styles.theme.button, 'search-button')}
                        onClick={() => this.onSearch()}
                    >
                        Search
                    </Button>
                    {userLoginRendered}
                </div>
            </Fragment>
        );
    }
}

// Inject the required contexts.
const TopBarSearchInternalWrapped = (props: TopBarSearchInternalProps) => {
    const authorizationContext = useContext(AuthorizationContext) as AuthorizationContextInterface;    
    const searchContext = useContext(SearchContext) as SearchContextProviderState

    return (
        <TopBarSearchInternal authorizationContext={authorizationContext} searchContext={searchContext} autoCompleteResult={props.autoCompleteResult} searchSettings={props.searchSettings} context={props.context} styles={props.styles}/>
    )
}

const TopBarSearch = connectTeamsTheme(TopBarSearchInternalWrapped as any);
export default TopBarSearch;