import React, { createContext } from 'react';
import { Tenant } from '../types/Tenants';
import { FacetConfiguration } from '../types/Facet';
import log from 'loglevel';
import { ServerErrorResponse } from '../utils/errors';
import { AuthenticationContext } from './AuthenticationContext';
import { mediasWithSummarization, Summarization } from '../types/Summarization';
import { DetectedFaces } from '../types/Faces';
import { AudioSearchHits, TextDiarizationSegments, TextSearchHits, Transcription } from '../types/transcription';
import { FieldConfiguration } from '../types/Field';
import { MediaDetail } from '../types/MediaDetail';
import MediaReference from '../logic/MediaReference';
import * as utils from '../utils/misc';
import defaultSummarizationData from '../logic/mockedApiData/getSummarization-default.json';
import { MediaActions } from '../types/MediaActions';
import { appConfig } from '../logic/appConfigProvider';

export interface AccessToken {
    token: string;
    expiresOn: string;
}

export interface SearchRequest {
    top: number;
    skip: number;
    query: string;
    sortBy: string;
    orderBy: string[];
    filter: string;
    sortOrder: string;
    searchFields: string[];
    facets: string[];
    select: string[];
    searchMode: string;
    includeTotalResultCount: boolean;
}

export interface AutoCompleteRequest {
    top: number;
    query: string;
    searchFields: string[];
}

export type ApiTokenResponse = {
    token: string,
    isSso: boolean,
    userTenantId: string,
    activeTenantId: string,
}

export interface AuthorizeResponse {
    isTenantKnown: boolean,
    isReplayEnabled?: boolean,
    userPermissions: string[]
    isDistributor: boolean
}

export interface ReProcessMediaRequest {
    transcription?: boolean,
    summarization?: boolean,
    videoIndexing?: boolean,
}


export interface DataContextProps {
    getVersion: () => Promise<any>;
    getAuthorize: () => Promise<AuthorizeResponse>;
    getTenants: () => Promise<Tenant[]>;
    getFacets: () => Promise<FacetConfiguration>;
    getFields: () => Promise<FieldConfiguration>;
    getSorters: () => Promise<any>;
    search: (searchRequest: SearchRequest) => Promise<any>;
    autoComplete: (autoCompleteRequest: any) => Promise<any>;
    getMediaInfo: (mediaId: any) => Promise<MediaDetail>;
    getMediaContentUrl: (mediaId: string, contentReference: string, validForSeconds: number) => Promise<any>;
    getMediaContent: (mediaId: string, contentReference: string) => Promise<any>;
    selectMediaContent: (mediaReferences: string[]) => Promise<any>;
    getTranscription: (mediaId: string) => Promise<Transcription>;
    getTextDiarization: (mediaId: string) => Promise<TextDiarizationSegments>;
    getTextSearchHits: (mediaId: string, search: string) => Promise<TextSearchHits>;
    getAudioSearchHits: (mediaId: string, search: string) => Promise<AudioSearchHits>;
    getGPSTrace: (mediaId: string) => Promise<any>;
    getAzureMapsToken: () => Promise<AccessToken>;
    getDetectedFaces: (mediaId: string) => Promise<DetectedFaces>;
    getSummarization: (mediaId: string) => Promise<Summarization>;
    getMediaActions: (mediaId: string) => Promise<MediaActions>;
    reprocessMedia: (mediaId: string, request: ReProcessMediaRequest) => Promise<any>;
}

export const DataContext = createContext<DataContextProps | null>(null);

interface DataProviderProviderProps {
    children?: React.ReactNode
}

interface DataProviderContextProviderState {
}

export class DataContextProvider extends React.Component<DataProviderProviderProps, DataProviderContextProviderState> {
    context!: React.ContextType<typeof AuthenticationContext>;
    static contextType = AuthenticationContext;

    getVersion = async (): Promise<any> => {
        return await this.executeJsonMethodOnApi('GET', 'version', null);
    };

    getAuthorize = async (): Promise<AuthorizeResponse> => {
        return await this.executeJsonMethodOnApi('GET', 'v2/authorize', null);
    };

    getTenants = async (): Promise<Tenant[]> => {
        return await this.executeJsonMethodOnApi('GET', `v2/tenant`, null);
    };

    getFacets = async (): Promise<FacetConfiguration> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('GET', `v2/facets`, null, headers);
    };

    getFields = async (): Promise<FieldConfiguration> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('GET', `v2/configuration/fields`, null, headers);
    };

    getSorters = async (): Promise<any> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('GET', `v2/sorters`, null, headers);
    };

    search = async (searchRequest: SearchRequest): Promise<any> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('POST', `v2/search`, searchRequest, headers);
    };

    autoComplete = async (autoCompleteRequest: any): Promise<any> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('POST', `v2/autocomplete`, autoCompleteRequest, headers);
    };

    getMediaInfo = async (mediaId: any): Promise<MediaDetail> => {
        var headers = this.getOnBeHalfOfHeaders();
        const json: string = await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/info`, null, headers);
        const mediaDetail: MediaDetail = MediaDetail.Create(json);
        return mediaDetail;
    };

    getMediaContentUrl = async (mediaId: string, contentReference: string, validForSeconds: number): Promise<any> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/contenturl?reference=${contentReference}&validforseconds=${validForSeconds}`, null, headers);
    };

    getMediaContent = async (mediaId: string, contentReference: string): Promise<any> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeBlobMethodOnApi('GET', `v2/media/${mediaId}/content?reference=${contentReference}`, null, headers);
    };

    selectMediaContent = async (mediaReferences: string[]): Promise<any> => {
        var headers = this.getOnBeHalfOfHeaders();

        const body = {
            mediaContentReferences: mediaReferences
        };

        const result = await this.executeJsonMethodOnApi('POST', `v2/media/content/select`, body, headers);

        const mediaContent = result.mediaContent;
        let resultMap = new Map();

        for (const entry of mediaContent) {
            const mediaReference = new MediaReference(entry.mediaId, entry.contentReference);
            const blob = utils.b64toBlob(entry.contentBase64);

            resultMap.set(mediaReference.toString(), blob);
        }

        return resultMap;
    };

    getTranscription = async (mediaId: string): Promise<Transcription> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/transcription`, null, headers);
    };

    getTextDiarization = async (mediaId: string): Promise<TextDiarizationSegments> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/textdiarization`, null, headers);
    };

    getTextSearchHits = async (mediaId: string, search: string): Promise<TextSearchHits> => {
        var headers = this.getOnBeHalfOfHeaders();
        const searchEscaped = encodeURIComponent(search);
        return await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/textsearchhits?search=${searchEscaped}`, null, headers);
    };

    getAudioSearchHits = async (mediaId: string, search: string): Promise<AudioSearchHits> => {
        var headers = this.getOnBeHalfOfHeaders();
        const searchEscaped = encodeURIComponent(search);
        return await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/audiosearchhits?search=${searchEscaped}`, null, headers);
    };

    getGPSTrace = async (mediaId: string): Promise<any> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/gpstrace`, null, headers);
    };

    getAzureMapsToken = async (): Promise<AccessToken> => {
        return await this.executeJsonMethodOnApi('GET', `v2/tokens/azuremaps`, null);
    };

    getDetectedFaces = async (mediaId: string): Promise<DetectedFaces> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/detectedfaces`, null, headers);
    };

    getSummarization = async (mediaId: string): Promise<Summarization> => {
        if (mediasWithSummarization.includes(mediaId)) {
            // This code should be removed when the Replay API supports summarization.
            return new Promise<Summarization>((resolve) => {
                let data = defaultSummarizationData;
                
                if (mediasWithSummarization.includes(mediaId)) {
                    data = require(`../logic/mockedApiData/getSummarization-${mediaId}.json`);
                }
                
                resolve(data);
            });
        } else {
            var headers = this.getOnBeHalfOfHeaders();
            return await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/summarization`, null, headers);
        }
    };

    getMediaActions = async (mediaId: string): Promise<MediaActions> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('GET', `v2/media/${mediaId}/actions`, null, headers);
    };

    reprocessMedia = async (mediaId: string, request: ReProcessMediaRequest): Promise<any> => {
        var headers = this.getOnBeHalfOfHeaders();
        return await this.executeJsonMethodOnApi('POST', `v2/media/${mediaId}/actions/reprocess`, request, headers);
    }


    /**
     * This function determines the headers to be used for "on behalf of" requests.
     * If the active tenant ID is the same as the user's tenant ID, it returns an empty object, meaning the request is a normal request and not on-behalf-of.
     */
    private getOnBeHalfOfHeaders(): Record<string, string> {
        // Check if the active tenant ID is the same as the user's tenant ID
        if (this.context?.activeTenantId === this.context?.userTenantId) {
            // Return empty headers if the tenant IDs match
            return {};
        }
        // Create headers with the active tenant ID
        const headers = { 'DistributorOnBehalfOfTenantId': this.context?.activeTenantId ?? "" };
        // Return the headers
        return headers;
    }



    private async executeJsonMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null, headers: Record<string, string> = {}): Promise<any> {
        const response = await this.executeMethodOnApi(method, relativeApiPath, jsonBody, headers);
        if (response.status === 204) {
            return Promise.resolve(null);
        }
        return await response.json();
    }

    private async executeBlobMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null, headers: Record<string, string> = {}): Promise<Blob> {
        const response = await this.executeMethodOnApi(method, relativeApiPath, jsonBody, headers);
        return await response.blob();
    }

    private async executeMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null, headers: Record<string, string> = {}): Promise<Response> {
        try {
            log.debug(`[executeCallOnBackend] Invoking '${method}' on '${relativeApiPath}'...`)

            const apiTokenResponse = await this.context?.getApiToken();

            if (!apiTokenResponse) {
                throw new Error("No API token received from token provider callback");
            }

            const basePath = apiTokenResponse.isSso ? 'replay-api/app' : 'replay-api';
            const url = `https://${appConfig.replayApiHostname}/${basePath}/${relativeApiPath}`;

            const requestHeaders: HeadersInit = new Headers(headers);
            requestHeaders.set('Authorization', `Bearer ${apiTokenResponse.token}`);

            const requestParameters: RequestInit = {
                method: method,
                headers: requestHeaders
            };

            // Add the body if one was provided.
            if (jsonBody) {
                requestHeaders.set('Content-Type', 'application/json');
                requestParameters.body = JSON.stringify(jsonBody);
            }

            const response = await fetch(url, requestParameters);

            if (!response.ok) {
                // Some non-success response received.
                throw new ServerErrorResponse(null, response.status, response.statusText, await response.text());
            }

            // A success response is received from the back-end.
            return response;
        }
        catch (error: any) {
            if (error instanceof ServerErrorResponse) {
                const responseBody = error.responseBody ? `\r\n${error.responseBody}` : "";
                log.error(`Server error response received while invoking '${method}' on '/${relativeApiPath}' endpoint. Received ${error.statusCode} '${error.statusText}'.${responseBody}`);
            } else {
                log.error(`Error occurred while invoking '${method}' on '/${relativeApiPath}': ${error.message}`);
            }

            throw (error);
        }
    }

    render() {
        return (
            <DataContext.Provider
                value={{
                    getTenants: this.getTenants,
                    getFacets: this.getFacets,
                    search: this.search,
                    autoComplete: this.autoComplete,
                    getFields: this.getFields,
                    getSorters: this.getSorters,
                    getMediaInfo: this.getMediaInfo,
                    getMediaContentUrl: this.getMediaContentUrl,
                    getMediaContent: this.getMediaContent,
                    selectMediaContent: this.selectMediaContent,
                    getTranscription: this.getTranscription,
                    getTextDiarization: this.getTextDiarization,
                    getTextSearchHits: this.getTextSearchHits,
                    getAudioSearchHits: this.getAudioSearchHits,
                    getGPSTrace: this.getGPSTrace,
                    getAzureMapsToken: this.getAzureMapsToken,
                    getDetectedFaces: this.getDetectedFaces,
                    getSummarization: this.getSummarization,
                    getMediaActions: this.getMediaActions,
                    reprocessMedia: this.reprocessMedia,
                    getVersion: this.getVersion,
                    getAuthorize: this.getAuthorize
                }}
            >
                {this.props.children}
            </DataContext.Provider>
        );
    }
}

export const DataConsumer = DataContext.Consumer;

export const withData = (Component: React.ComponentType<any>) => {
    return function DataComponent(props: any) {
        return (
            <DataConsumer>
                {(context) => {
                    if (!context) {
                        throw new Error('withData must be used within a DataProvider');
                    }
                    return <Component {...props} data={context} />;
                }}
            </DataConsumer>
        );
    };
};
