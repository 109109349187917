import { Component, useContext } from 'react';
import TraceMap from './TraceMap';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { DataContext, DataContextProps } from '../../context/DataProviderContext';

type MediaTraceMapProps = {
    dataContext: DataContextProps;
    isPanelOpen: boolean, // ToDo: Is this still required? (copied from TranscriptionTab.tsx)   
    play: boolean,
    startTime: Date,
    timeOffSetNavigationPointer: number,
    mediaId: string
}

type MediaTraceMapState = {
    traceData: any
}

class MediaTraceMapInternal extends Component<MediaTraceMapProps, MediaTraceMapState> {   
    constructor(props: MediaTraceMapProps) {
        super(props)
        this.state = {
            traceData: null
        }
    }

    componentDidMount() {
        this.getGPSTraceData(this.props.mediaId);
    }
    
    getGPSTraceData = async (mediaId: string) => {
        const mediaTranscription = await this.props.dataContext.getGPSTrace(mediaId);
        this.setState({traceData: mediaTranscription});        
    }

    render() {
        const { isPanelOpen } = this.props;

        return (
            <div className={`video-side-bar ${isPanelOpen && ('active')}`} style={{ height: '70vh'  }}>
                <TraceMap traceData={this.state.traceData} timeOffSetNavigationPointer={this.props.timeOffSetNavigationPointer}
                    startTime={this.props.startTime}/>
            </div>
        );
    }
}

const MediaTraceMap: React.FunctionComponent<MediaTraceMapProps> = (props: MediaTraceMapProps) => {
    const dataContext = useContext(DataContext);
    if (!dataContext) {
        throw new Error("No dataContext found in React tree.");
    }

    return (
        <MediaTraceMapInternal {...props} dataContext={dataContext} />
    )
}

export default connectTeamsTheme(MediaTraceMap);
  