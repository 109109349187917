import React, { Fragment, useState, useEffect, useContext } from "react";
import "./TeamsRecordingSearchResult.scss";
import { urls } from "../../logic/urls";
import { thumbnailProvider } from "../../logic/thumbnailProvider";
import { toDisplayString } from "../../logic/displayString";
import {
  ArrowLeft32Filled as ArrowLeftIcon,
  ArrowRight32Filled as ArrowRightIcon,
} from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import MediaActionsMenu from "../common/menu/MediaActions";
import { onDownload } from "../../logic/downloadHandler";
import { useLocation } from "react-router-dom";
import { DataContext } from '../../context/DataProviderContext';

const TOKEN_VALID_TIME_SECONDS = 3600;

export const TeamsRecordingSearchResult = ({ item, onClick }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  
  const dataContext = useContext(DataContext);

  const location = useLocation()

  useEffect(() => {
    async function getThumbnail() {
      // 'Audio only' media always have the same thumbnail, so it it al lot of overhead to retrieve this from the back-end.
      // For this reason the 'audio only' thumbnail is directly retrieved from the webserver (and thus will be cached by the browser).
      if (
        !item.mediaTypes.includes("video") &&
        !item.mediaTypes.includes("screen")
      ) {
        setThumbnailUrl(urls.thumbnailAudio);
        return;
      }

      if (item.playAllowed === false) {
        setThumbnailUrl(urls.thumbnailNoContentReference);
        return;
      }

      let thumbnailReference = item.contentReferences.find(
        (item) => item.type === "thumbnail"
      );
      if (!thumbnailReference) {
        setThumbnailUrl(urls.thumbnailNoContentReference);
        return;
      }

      try {        
        const objectUrl = await thumbnailProvider.getThumbnail(
            item.mediaId,
            thumbnailReference.reference,
            dataContext.getMediaContentUrl,
            dataContext.selectMediaContent
        );
        setThumbnailUrl(objectUrl);
        return;
      } catch (error) {
        setThumbnailUrl(urls.thumbnailRetrievalError);
        return;
      }
    }

    getThumbnail();
  }, [
    item.contentReferences,
    item.mediaId,
    item.mediaTypes,
    item.playAllowed,
    dataContext.getMediaContentUrl,
    dataContext.selectMediaContent,
  ]);

  const handleOnClick = () => {
    if (item.playAllowed) {
      onClick(item.mediaId);
    }
  };
  const handleOnMouseEnter = () => {
    setIsHovered(true);
  };
  const handleOnMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOnDownload = async () => {
    let audioAndVideoReferences = item.contentReferences.filter((reference) => reference.type === 'audio' || reference.type === 'video');
    const contentReference = audioAndVideoReferences[0].reference;
    const contentUrl = await dataContext.getMediaContentUrl(item.mediaId, contentReference, TOKEN_VALID_TIME_SECONDS);
    onDownload(contentUrl.url)
}
  
  const thumbnailUrlToDisplay =
    thumbnailUrl !== "" ? thumbnailUrl : urls.thumbnailPlaceholder;
  const showThumbnail = thumbnailUrl !== "";

  let topLineRendered;
  
  if (item.showArrow) {
    const iconType = item.showLeftArrow ? (
      <ArrowLeftIcon />
    ) : (
      <ArrowRightIcon />
    );

    topLineRendered = (
      <>
        {item.ownerName}
        <Button
          appearance="transparent"
          icon={iconType}
          style={{ height: "11px", padding: "0" }}
        />
        {item.otherParticipantName}
      </>
    );
  } else {
    topLineRendered = <>{item?.topLine}</>;
  }
  
  let sourceTypeIcon;
  switch (item.sourceType) {
    case "teams":
    case "bodyCam":
    case "cybergate":
      sourceTypeIcon = (
        <div
          className={`option-icon ${item.sourceType}`}
          title={toDisplayString(item.sourceType)}
        />
      );
      break;
    case "fixedLine":
      sourceTypeIcon = (
        <div
          className="option-icon material-icons"
          title={toDisplayString(item.sourceType)}
        >
          phone
        </div>
      );
      break;
    case "mobile":
      sourceTypeIcon = (
        <div
          className="option-icon material-icons"
          title={toDisplayString(item.sourceType)}
        >
          phone_iphone
        </div>
      );
      break;
    default:
      sourceTypeIcon = null;
      break;
  }

  return (
    <Fragment>
      <section
        className="result-item"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <div onClick={handleOnClick} className="thumbnail-support">
          <img
            className={`${showThumbnail ? "displayThumbnail" : ""}`}
            src={thumbnailUrlToDisplay}
            alt="Thumbnail"
          />
          {item.playAllowed ? (
            <img
              className={`overlay shadowed`}
              src={urls.thumbnailOverlay}
              alt="Overlay"
            />
          ) : (
            <></>
          )}
        </div>
        <div className="text-result">
          <h3>{topLineRendered}</h3>
          <div className="row">
            <i>{item.subline}</i>
          </div>
          <div className="row">
            <b>{item.startTimeText}</b>, {item.durationText}
          </div>
          <div className="options row">
            {sourceTypeIcon}
            {item.hasNoAudio ? (
              <div className="option-icon material-icons" title="Audio">
                volume_off
              </div>
            ) : null}
            {item.hasVideo ? (
              <div className="option-icon material-icons" title="Video">
                videocam
              </div>
            ) : null}
            {item.hasScreenSharing ? (
              <div
                className="option-icon material-icons"
                title="Screen sharing"
              >
                screen_share
              </div>
            ) : null}
          </div>
        </div>
        <div className="result-item-menu">
          {/* <div style={{float: 'right'}}>
                        <MediaActionsMenu mediaId={document.mediaId} />
                    </div> */}
          {isHovered && (
            <div>
              <MediaActionsMenu mediaId={item.mediaId} onDownload={handleOnDownload} isHovered={isHovered} location={location.pathname}/>
            </div>
          )}
        </div>
      </section>
    </Fragment>
  );
};
