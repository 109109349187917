import { LocationSelectorControl } from '../MapControl';
import * as atlasMapControl from 'azure-maps-control';
import { AzureMapControl } from './AzureMap';
import { AccessToken } from '../../../context/DataProviderContext';

declare const atlas: any;

export interface AzureLocationSelectorProps {
    elementId: string;
    getAzureMapsToken: () => Promise<AccessToken>;
    radius: number;
    onMapRead?: () => void;
    onSelectedLocationChanged? (longitude: number, latitude: number): void;
}

class AzureLocationSelector extends AzureMapControl implements LocationSelectorControl {
    dataSource: any;
    elementId: string;
    props: AzureLocationSelectorProps;
    selectedShape: any;
    pointSelectorCreated: boolean;
    point: any; // atlas.data.Feature

    public constructor(props: AzureLocationSelectorProps) {
        super({
            elementId: props.elementId,
            getAzureMapsToken: props.getAzureMapsToken
        });
        this.pointSelectorCreated = false;
        this.props = props;
        this.elementId = props.elementId;
    }

    setRadius(radius: number): void {
        this.props.radius = radius;

        if (this.pointSelectorCreated){
            this.point.addProperty("radius", radius);
        }        
    }    

    onMapReady(evt: any): void {   
        this.registerMapEventHandlers();

        this.addControls();

        if (this.props.onMapRead) {
            this.props.onMapRead();
        }
    }

    registerMapEventHandlers(): void{
        this.azMap.events.add('click', (evt: any) => this.onMapClick(evt));
    }

    onMapClick(evt: any): void {
        if (this.pointSelectorCreated === false){
            this.addPointSelector(evt.position);   
            
            if (this.props.onSelectedLocationChanged){
                this.props.onSelectedLocationChanged(evt.position[0], evt.position[1]);
            }
        }
    }

    addControls(): void {
        let controls = [
            new atlasMapControl.control.ZoomControl(),
            new atlasMapControl.control.StyleControl()
        ];
        let position: atlasMapControl.ControlPosition = atlasMapControl.ControlPosition.BottomRight;

        controls.forEach((control) => {
            /* Add the controls to the map*/
            this.azMap.controls.add(control, { position: position });
        });
    }

    addPointSelector(position: any): void{
         //Create a data source and add it to the map.
         this.dataSource = new atlas.source.DataSource();
         this.azMap.sources.add(this.dataSource);

        //Create a circle from a Point Feature and wrap it with the Shape class.
        this.point = new atlas.Shape(new atlas.data.Point(position), null, {
            subType: "Circle",
            radius: this.props.radius
        });

         //Create a circle from a Point feature by providing it a subType property set to "Circle" and radius property.
         this.dataSource.add(this.point);

         let layer = new atlas.layer.PolygonLayer(this.dataSource, null, {
            fillColor: '#1a73aa'
        });

         //Create a polygon layer to render the filled in area of the circle polygon, and add it to the map.
         this.azMap.layers.add(layer);

         this.azMap.events.add('mousedown', layer, (e: any) => {
            if (e.shapes && e.shapes.length > 0) {
                //Capture the selected shape.
                this.selectedShape = e.shapes[0];

                //Lock the maps ability to pan so that we can drag the symbol.
                this.azMap.setUserInteraction({
                    dragPanInteraction: false
                });
            }
         });

         this.azMap.events.add('mousemove', layer, (e: any) => {
            //Update the position of the selected shape.
            if (this.selectedShape) {
                this.selectedShape.setCoordinates(e.position);
            }
         });         

         this.azMap.events.add('mouseup', layer, (e: any) => {
            //Stop tracking the selected shape.
            this.selectedShape = null;

            //Make map panable again.
            this.azMap.setUserInteraction({
                dragPanInteraction: true
            });
         });   
         
         this.pointSelectorCreated = true;
    }

}

const createAzMap = (props: AzureLocationSelectorProps): LocationSelectorControl => {
    return new AzureLocationSelector(props);
}

export {
    createAzMap
}