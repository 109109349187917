import {MapControl} from '../MapControl';
import * as atlasMapControl from 'azure-maps-control';
import { appConfig } from '../../../logic/appConfigProvider';
import { AccessToken } from '../../../context/DataProviderContext';

export interface AzureMapControlProps {
    elementId: string;    
    getAzureMapsToken: () => Promise<AccessToken>;
}

export class AzureMapControl implements MapControl {
    azMap!: atlasMapControl.Map;
    dataSource: any;
    pinDataSource: any;
    elementId: string;    
    animation: any;
    props: AzureMapControlProps;
    navigationPin: any;
    follow: boolean = false;
    currentPositionNavigationPin: any;

    public constructor(props: AzureMapControlProps) {
        this.props = props;
        this.elementId = props.elementId;
        this.initializeMap();        
    }               
    
    private initializeMap(): void {
        var getTokenFunction = this.props.getAzureMapsToken;
        
        //Initialize a map instance.
        this.azMap = new atlasMapControl.Map(this.elementId, {
            authOptions: {
                authType: atlasMapControl.AuthenticationType.anonymous,
                clientId: appConfig.azMapsClientId, 
                getToken:  function (resolve, reject, map) {        
                    getTokenFunction().then((accessToken: AccessToken) => {                        
                        resolve(accessToken.token);
                    });
                }
            },

            center: [4.7534, 52.6324],
            zoom: 10,
            view: 'Auto',
            showLogo: false,
            renderWorldCopies: false,    
            showFeedbackLink: false,
        });

        this.azMap.events.add('ready',(evt: any) => this.mapReady(evt));
    };

    private mapReady(evt: any): void {
        this.onMapReady(evt);
    }

    onMapReady(evt: any): void {
    }
}
